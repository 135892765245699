import React from 'react';
import Footer from "../footer/footer"
const pointsTable = () =>{
  return (
    <div style={{display:'grid',justifyContent:'center',alignItems:'center'}}>
      <h3>For any kind of information,</h3>
      <p>mail us at: primeticket.co@gmail.com</p>
      <Footer/>
    </div>
  );
}
export default pointsTable;