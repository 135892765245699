import React, { useContext } from "react";
import "./team.css";
import logo from "./assets/LOGO 2.png";
import card1 from "./assets/vg 1.png";
import card2 from "./assets/Adelaide Super Kings.png";
import card3 from "./assets/Brisbane Bulls.png";
import card4 from "./assets/Chennai Wolves.png";
import card5 from "./assets/Colombo Strikers.png";
import card6 from "./assets/Delhi Heats.png";
import card7 from "./assets/Dhaka Tigers.png";
import card8 from "./assets/Kabul zalmi.png";
import card9 from "./assets/Karachi Sufis.png";
import card10 from "./assets/Kolkata Risers.png";
import card11 from "./assets/Lahore.jpg";
import card12 from "./assets/Melbourne Marvels.png";
import card13 from "./assets/Perth Panthers.png";
import card14 from "./assets/Punjab Warriors.png";
import card15 from "./assets/Sydney Titans.png";
import card16 from "./assets/mumbai blasters.png";
import Footer from "../footer/footer";
import { TeamContext } from "../registration/TeamContext"; // Import the context
import { useNavigate } from "react-router-dom";

const Main = () => {
  const { setSelectedTeam } = useContext(TeamContext);
   const naviagte = useNavigate();
  const handleCardClick = (teamName) => {
    setSelectedTeam(teamName);
    naviagte(`/register?team=${teamName}`);
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <div className="logo">
        <img className="img" src={logo} alt="Logo" />
      </div>
      <div className="contain">
        <div className="card" style={{ backgroundColor: "darkblue" }} onClick={() => handleCardClick("Vibrant Gujarat")}>
          <div style={{ backgroundColor: "aqua" }} className="upper">
            <img className="cardImg" src={card1} alt="Vibrant Gujarat" />
          </div>
          <div className="teamName">Vibrant Gujarat</div>
        </div>
        <div className="card" style={{ backgroundColor: "midnightblue" }} onClick={() => handleCardClick("Adelaide Super Kings")}>
          <div style={{ backgroundColor: "red" }} className="upper">
            <img className="cardImg" src={card2} alt="Adelaide Super Kings" />
          </div>
          <div className="teamName">Adelaide Super Kings</div>
        </div>
        <div className="card" style={{ backgroundColor: "brown" }} onClick={() => handleCardClick("Brisbane Bulls")}>
          <div className="upper" style={{ backgroundColor: "orange" }}>
            <img className="cardImg" src={card3} alt="Brisbane Bulls" />
          </div>
          <div className="teamName">Brisbane Bulls</div>
        </div>
        <div className="card" style={{ backgroundColor: "blue" }} onClick={() => handleCardClick("Chennai Wolves")}>
          <div className="upper" style={{ backgroundColor: "white" }}>
            <img className="cardImg" src={card4} alt="Chennai Wolves" />
          </div>
          <div className="teamName">Chennai Wolves</div>
        </div>
        <div className="card" style={{ backgroundColor: "purple" }} onClick={() => handleCardClick("Colombo Strikers")}>
          <div className="upper" style={{ backgroundColor: "pink" }}>
            <img className="cardImg" src={card5} alt="Colombo Strikers" />
          </div>
          <div className="teamName">Colombo Strikers</div>
        </div>
        <div className="card" style={{ backgroundColor: "darkgreen" }} onClick={() => handleCardClick("Delhi Heats")}>
          <div className="upper" style={{ backgroundColor: "yellow" }}>
            <img className="cardImg" src={card6} alt="Delhi Heats" />
          </div>
          <div className="teamName">Delhi Heats</div>
        </div>
        <div className="card" style={{ backgroundColor: "blueviolet" }} onClick={() => handleCardClick("Kabul Zalmi")}>
          <div className="upper" style={{ backgroundColor: "white" }}>
            <img className="cardImg" src={card8} alt="Kabul Zalmi" />
          </div>
          <div className="teamName">Kabul Zalmi</div>
        </div>
        <div className="card" style={{ backgroundColor: "gray" }} onClick={() => handleCardClick("Karachi Sufis")}>
          <div className="upper" style={{ backgroundColor: "darkorange" }}>
            <img className="cardImg" src={card9} alt="Karachi Sufis" />
          </div>
          <div className="teamName">Karachi Sufis</div>
        </div>
        <div className="card" style={{ backgroundColor: "white" }} onClick={() => handleCardClick("Kolkata Risers")}>
          <div className="upper" style={{ backgroundColor: "darkblue" }}>
            <img className="cardImg" src={card10} alt="Kolkata Risers" />
          </div>
          <div className="teamName">Kolkata Risers</div>
        </div>
        <div className="card" style={{ backgroundColor: "darkblue" }} onClick={() => handleCardClick("Lahore Badshahs")}>
          <div className="upper" style={{ backgroundColor: "darkgoldenrod" }}>
            <img className="cardImg" src={card11} alt="Lahore Badshahs" />
          </div>
          <div className="teamName">Lahore Badshahs</div>
        </div>
        <div className="card" style={{ backgroundColor: "indigo" }} onClick={() => handleCardClick("Punjab Warriors")}>
          <div className="upper" style={{ backgroundColor: "hotpink" }}>
            <img className="cardImg" src={card14} alt="Punjab Warriors" />
          </div>
          <div className="teamName">Punjab Warriors</div>
        </div>
        <div className="card" style={{ backgroundColor: "midnightblue" }} onClick={() => handleCardClick("Melbourne Marvels")}>
          <div className="upper" style={{ backgroundColor: "red" }}>
            <img className="cardImg" src={card12} alt="Melbourne Marvels" />
          </div>
          <div className="teamName">Melbourne Marvels</div>
        </div>
        <div className="card" style={{ backgroundColor: "darkblue" }} onClick={() => handleCardClick("Sydney Titans")}>
          <div className="upper" style={{ backgroundColor: "aqua" }}>
            <img className="cardImg" src={card15} alt="Sydney Titans" />
          </div>
          <div className="teamName">Sydney Titans</div>
        </div>
        <div className="card" style={{ backgroundColor: "purple" }} onClick={() => handleCardClick("Mumbai Blasters")}>
          <div className="upper" style={{ backgroundColor: "lightblue" }}>
            <img className="cardImg" src={card16} alt="Mumbai Blasters" />
          </div>
          <div className="teamName">Mumbai Blasters</div>
        </div>
        <div className="card" style={{ backgroundImage: "linear-gradient(to bottom,blue,red)" }} onClick={() => handleCardClick("Dhaka Tigers")}>
          <div className="upper" style={{ backgroundColor: "white" }}>
            <img className="cardImg" src={card7} alt="Dhaka Tigers" />
          </div>
          <div className="teamName">Dhaka Tigers</div>
        </div>
        <div className="card" style={{ backgroundImage: "linear-gradient(to bottom,black,gray)" }} onClick={() => handleCardClick("Perth Panthers")}>
          <div className="upper" style={{ backgroundColor: "cadetblue" }}>
            <img className="cardImg" src={card13} alt="Perth Panthers" />
          </div>
          <div className="teamName">Perth Panthers</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Main;
