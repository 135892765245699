import React from 'react';
import Footer from "../footer/footer"
const Matches = () =>{
  return (
    <div>
      <h3 style={{justifyContent:'center', alignItems:'center', display:"flex"}}>COMING SOON</h3>
      <Footer/>
    </div>
  );
}
export default Matches;