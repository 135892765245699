import React, { useState, useContext, useEffect } from 'react';
import './style.css';
import { database } from './firebase-config';
import { UserContext } from '../../userContext'; 
import { TeamContext } from './TeamContext'; // Import the TeamContext
import { get, query, orderByChild, equalTo, child, ref, set } from 'firebase/database';


const RegistrationForm = () => {
    const { registerUser } = useContext(UserContext);
    const { selectedTeam } = useContext(TeamContext); // Use the selected team context
    const [formData, setFormData] = useState({
        fullName: '',
        username: '',
        email: '',
        phoneNumber: '',
        team: '',
        customTeamName: ''
    });

    useEffect(() => {
        if (selectedTeam) {
            setFormData(prevData => ({ ...prevData, team: selectedTeam }));
        }
    }, [selectedTeam]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleRadioChange = (e) => {
        setFormData({ ...formData, team: e.target.value, customTeamName: '' });
    };

    const handleCustomTeamChange = (e) => {
        setFormData({ ...formData, customTeamName: e.target.value, team: 'Custom' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userId = new Date().getTime().toString(); 
        const { email } = formData;

        const snapshot = await get(query(child(ref(database), 'CricketForm'), orderByChild('email'), equalTo(email)));
        console.log(snapshot);

        if (snapshot.exists()) {
            alert('Email already exists. Please use a different email address.');
            return;
        }

        try {
            await set(ref(database, 'CricketForm/' + userId), formData);
            registerUser(formData.username);
            alert('Registration Successful');
        } catch (error) {
            alert('Registration Failed: ' + error.message);
        }
    };

    return (
        <div className="body">
            <div className="container">
                <div className="title">Registration</div>
                <div className="content">
                    <form id="registrationForm" onSubmit={handleSubmit}>
                        <div className="alert">Registration Successful</div>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Full Name</span>
                                <input
                                    type="text"
                                    id="fullName"
                                    placeholder="Enter your name"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <span className="details">Username</span>
                                <input
                                    type="text"
                                    id="username"
                                    placeholder="Enter your username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <span className="details">Email</span>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="input-box">
                                <span className="details">Phone Number</span>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    placeholder="Enter your number"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="team-details">
                            <span className="team-title">Cricket Team Franchise:</span><br />
                            <span>Available to buy</span>
                            <div className="category">
                                {/* Radio buttons for team selection */}
                                {/* Example team radio button */}
                                <div className="radio-row">
                                    <input
                                        type="radio"
                                        id="vibrant_gujarat"
                                        name="team"
                                        value="Vibrant Gujarat"
                                        disabled
                                    />
                                    <label htmlFor="vibrant_gujarat">Vibrant Gujarat (SOLD)</label>
                                </div>
                                <div>
                <input
                  type="radio"
                  id="lahore_badshah"
                  name="team"
                  value="Lahore Badshahs"
                  disabled
                />
                <label htmlFor="lahore_badshah">Lahore Badshahs (SOLD)</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="dhaka_tigers"
                  name="team"
                  value="Dhaka Tigers"
                  checked={formData.team === 'Dhaka Tigers'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="dhaka_tigers">Dhaka Tigers</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="columbo_strikers"
                  name="team"
                  value="Columbo Strikers"
                  checked={formData.team === 'Columbo Strikers'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="columbo_strikers">Columbo Strikers</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="kolkata_risers"
                  name="team"
                  value="Kolkata Risers"
                  checked={formData.team === 'Kolkata Risers'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="kolkata_risers">Kolkata Risers</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="delhi_heats"
                  name="team"
                  value="Delhi Heats"
                  checked={formData.team === 'Delhi Heats'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="delhi_heats">Delhi Heat</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="karachi_sufis"
                  name="team"
                  value="Karachi Sufis"
                  checked={formData.team === 'Karachi Sufis'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="karachi_sufis">Karachi Sufis</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="punjab_warriors"
                  name="team"
                  value="Punjab Warriors"
                  checked={formData.team === 'Punjab Warriors'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="punjab_warriors">Punjab Warriors</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="mumbai_blazers"
                  name="team"
                  value="Mumbai Blazers"
                  checked={formData.team === 'Mumbai Blazers'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="mumbai_blazers">Mumbai Blazers</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="kabul_zalmi"
                  name="team"
                  value="Kabul Zalmi"
                  checked={formData.team === 'Kabul Zalmi'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="kabul_zalmi">Kabul Zalmi</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="chennai_wolves"
                  name="team"
                  value="Chennai Wolves"
                  checked={formData.team === 'Chennai Wolves'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="chennai_wolves">Chennai Wolves</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="brisbane_bulls"
                  name="team"
                  value="Brisbane Bulls"
                  checked={formData.team === 'Brisbane Bulls'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="brisbane_bulls">Brisbane Bulls</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="sydney_titans"
                  name="team"
                  value="Sydney Titans"
                  checked={formData.team === 'Sydney Titans'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="sydney_titans">Sydney Titans</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="melbourne_marvels"
                  name="team"
                  value="Melbourne Marvels"
                  checked={formData.team === 'Melbourne Marvels'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="melbourne_marvels">Melbourne Marvels</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="perth_panthers"
                  name="team"
                  value="Perth Panthers"
                  checked={formData.team === 'Perth Panthers'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="perth_panthers">Perth Panthers</label><br />
              </div>
              <div>
                <input
                  type="radio"
                  id="adelaide_super_kings"
                  name="team"
                  value="Adelaide Super Kings"
                  checked={formData.team === 'Adelaide Super Kings'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="adelaide_super_kings">Adelaide Super Kings</label><br />
              </div>
                                {/* Other radio buttons */}
                                {/* Add more radio buttons as needed */}
                                
                                {/* Custom team input */}
                                <div>
                                    <input
                                        type="radio"
                                        id="custom_team"
                                        name="team"
                                        value="Custom"
                                        checked={formData.team === 'Custom'}
                                        onChange={handleRadioChange}
                                    />
                                    <label htmlFor="custom_team">Custom (Please specify)</label><br />
                                    <input
                                        type="text"
                                        id="custom_team_name"
                                        name="custom_team_name"
                                        placeholder="Choose your own name"
                                        value={formData.customTeamName}
                                        onChange={handleCustomTeamChange}
                                    /><br />
                                </div>
                            </div>
                        </div>
                        <div className="button">
                            <input type="submit" value="Register" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;
