
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import logo from './assets/LOGO 2.png';
import { UserContext } from '../../userContext';

const Navbar = () => {
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className='navbar'>
        <div className='logo'>
          <img className='imgb' src={logo} alt='car' />
        </div>
        <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          &#9776;
        </div>
        <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
          <Link to='/' className='navbar-link'>MATCHES</Link>
          {/* <Link to='/videos' className='navbar-link'>VIDEOS</Link> */}
          <Link to='/gallery' className='navbar-link'>GALLERY</Link>
          <Link to='/team' className='navbar-link'>TEAM</Link>
          <Link to='/contact-us' className='navbar-link'>CONTACT US</Link>
          <Link to='/login' className='navbar-link'>LOGIN <br/></Link>
          {user.isRegistered ? (
            <span className='navbar-link'>Welcome, {user.username}<br/> to {user.team}</span>
            
          ) : (
            <Link to='/register' className='navbar-link'>Register</Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';
// import { UserContext } from '../../userContext';
// import './navbar.css';
// import logo from './assets/LOGO 2.png';
// const Navbar = () => {
//   const { user } = useContext(UserContext);

//   return (
//     <nav>
//       <h1>My App</h1>
//       <ul>
//         <li><Link to="/">Home</Link></li>
//         <li><Link to="/matches">Matches</Link></li>
//         <li><Link to="/videos">Videos</Link></li>
//         <li><Link to="/gallery">Fantasy</Link></li>
//         <li><Link to="/stats">Stats</Link></li>
//         <li><Link to="/team">Team</Link></li>
//         <li><Link to="/contact-us">Points Table</Link></li>
//         {user.isRegistered ? (
//           <li><Link to="/profile">Profile</Link></li>
//         ) : (
//           <li><Link to="/login">Login</Link></li>
//         )}
//       </ul>
//       {user.isRegistered ? (
//         <p>Welcome, {user.username}! ({user.team})</p>
//       ) : (
//         <p>Please log in</p>
//       )}
//     </nav>
//   );
// };

// export default Navbar;
