import React from 'react';
import "./team.css"
import logo from "./assets/LOGO 2.png"
import Footer from "../footer/footer"
const fantasy = () =>{
  return (
    <div>
    <div className="logo">
        <img className="img"src={logo}/>
    </div>
    <div className="contain">
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    {/* <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div>
    <div className="card" ></div> */}
    </div>
    <Footer/>
</div>
)}
export default fantasy;