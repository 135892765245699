import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Matches from "./components/navbar/matches";
import Videos from "./components/navbar/videos";
import Stats from "./components/navbar/stats";
import Fantasy from "./components/navbar/fantasy";
import PointsTable from "./components/navbar/pointsTable";
import Team from "./components/navbar/team";
import Footer from "./components/footer/footer";
import RegistrationForm from "./components/registration/registartionForm"; // Correct import path
import { UserProvider } from './userContext';
import { TeamProvider } from './components/registration/TeamContext'; // Import the TeamProvider
import Login from "./components/registration/login"
function App() {
  return (
    <UserProvider>
      <TeamProvider>
        <Router>
          <Navbar />
          <Routes>
          <Route path='/login' element={<Login />} />
            <Route path='/register' element={<RegistrationForm />} />
            <Route path='/' element={<Team />} />
            <Route path='/matches' element={<Matches />} />
            <Route path='/videos' element={<Videos />} />
            <Route path='/gallery' element={<Fantasy />} />
            <Route path='/stats' element={<Stats />} />
            <Route path='/team' element={<Team />} />
            <Route path='/contact-us' element={<PointsTable />} />
            <Route path='/footer' element={<Footer />} />
          </Routes>
        </Router>
      </TeamProvider>
    </UserProvider>
  );
}

export default App;
