// firebase-config.js
import { initializeApp } from 'firebase/app';
import { getDatabase,ref,get,set } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDDIads1VEC78IV2KIpRHilpcqEMoJ25RE",
  authDomain: "cricketform-69eb4.firebaseapp.com",
  databaseURL: "https://cricketform-69eb4-default-rtdb.firebaseio.com",
  projectId: "cricketform-69eb4",
  storageBucket: "cricketform-69eb4.appspot.com",
  messagingSenderId: "412764181690",
  appId: "1:412764181690:web:ca63a058eacecd6fe35e83"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database,ref,get,set };
