import React, { useState, useContext } from 'react';
import { ref, get, child, query, orderByChild, equalTo } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { database } from './firebase-config';
import { UserContext } from '../../userContext';
import "./login.css"
import Footer from "../footer/footer"
const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    username: ''
  });
  const { loginUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, username } = formData;

    const snapshot = await get(query(child(ref(database), 'CricketForm'), orderByChild('email'), equalTo(email)));
    const userData = snapshot.val();

    if (userData) {
      const userKey = Object.keys(userData)[0]; // Get the first key (user ID)
      const user = userData[userKey];
      
      if (user.username === username) {
        loginUser(user); // Set the logged-in user in the context
        navigate('/'); // Redirect to home page after successful login
      } else {
        alert('Invalid email or username');
      }
    } else {
      alert('Invalid email or username');
    }
  };

  return (
    <div className='login'>
      <div className='heading'>
      <h2 >Login</h2>
      {/* <p className='subheading'>Login with another account</p> */}
      <form className='form' onSubmit={handleSubmit}>
        <div>
          <label className="label"htmlFor="email">Email:</label>
          <br/>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label className="label" htmlFor="username">Username:</label>
          <br/>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <button className='submit' type="submit">Login</button>
      </form>
      
      </div>
    </div>
  );
};

export default LoginForm;
