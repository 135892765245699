// // UserContext.js
// import React, { createContext, useState, useEffect } from 'react';

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState({ isRegistered: false, username: ''});

//   useEffect(() => {
//     const storedUser = JSON.parse(localStorage.getItem('user'));
//     if (storedUser) {
//       setUser(storedUser);
//     }
//   }, []);

//   const registerUser = (username) => {
//     const userData = { isRegistered: true, username };
//     setUser(userData);
//     localStorage.setItem('user', JSON.stringify(userData));
//   };

//   return (
//     <UserContext.Provider value={{ user, registerUser }}>
//       {children}
//     </UserContext.Provider>
//   );
// };
import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ isRegistered: false, username: '', team: '' });

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const registerUser = (username, team) => {
    const userData = { isRegistered: true, username, team };
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const loginUser = (user) => {
    const userData = { isRegistered: true, username: user.username, team: user.team };
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  return (
    <UserContext.Provider value={{ user, registerUser, loginUser }}>
      {children}
    </UserContext.Provider>
  );
};

