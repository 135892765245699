import React from 'react';
import Footer from "../footer/footer"
const videos = () =>{
  return (
    <div>
      <h3>Coming Soon</h3>
      <Footer/>
    </div>
  );
}
export default videos;