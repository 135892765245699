import React from "react";
import "./footer.css";
import logo from "../navbar/assets/LOGO 2.png"
function Footer() {
  return (
    <div className="main">
      <div className="footer">
        <div>
          <div className="content">
            <img className="img"src={logo}/>
            <p>Australia Cricket Tournamnet</p>
          </div>
        </div>
        <div>
          <h1>GALLERY</h1>
          <div className="content">
            <a href="#match-photos">Match Photos</a>
            <a href="#team-photos">Team Photos</a>
            <a href="#fans-gallery">Fans Gallery</a>
          </div>
        </div>
        <div>
          <h1>CONTACT US</h1>
          <div className="content">
            <a href="#contact-form">Dhaval Amin</a>
            <a href="#support">primeticket.co@gmail.com</a>
            <a href="#careers">Careers</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
